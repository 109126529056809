<template>
	<!-- two separate <b-navbar-nav> elements with different classes for desktop and mobile view. One will be hidden on desktop screens and visible on mobile, and the other will be hidden on mobile screens and visible on desktop.
 -->
	<b-navbar toggleable="lg" type="light" class="sticky-top navbar-custom">
		<b-container>
			<!-- Nabvar Toggle -->

			<!-- <b-navbar-toggle target="nav-collapse">
				<button
					type="button"
					class="navbar-toggler"
					@click="toggleCollapsed"
					:aria-expanded="!isCollapsed"
					aria-controls="nav-collapse"
					style="position: relative"
				>
					<transition name="icon-fade">
						<img
							v-if="!isCollapsed"
							:key="'hamburger'"
							:src="hamburgerIcon"
							alt="Menu"
							style="position: absolute"
						/>
						<img
							v-if="isCollapsed"
							:key="'cross'"
							:src="crossIcon"
							alt="Close"
							style="position: absolute"
						/>
					</transition>
				</button>
			</b-navbar-toggle> -->

			<!-- Logo on large screens -->
			<!-- <b-navbar-brand class="mx-auto logo-lg d-none d-lg-block">
  <router-link :to="{ name: 'Landing Page' }">
    <img :src="logo" alt="Logo" class="img-fluid logo" />
  </router-link>			</b-navbar-brand> -->

			<!-- Logo on small screens -->
			<b-navbar-brand class="mx-auto d-lg-none">
				<router-link to="/">
					<img :src="logo" alt="Logo" class="img-fluid logo" />
				</router-link>
			</b-navbar-brand>

			<b-collapse
				id="nav-collapse"
				is-nav
				@show="collapsed = true"
				@hidden="collapsed = false"
			>
				<!-- Nav items for desktop -->
				<b-navbar-nav
					class="d-none d-lg-flex w-100 justify-content-between align-items-center"
				>
					<div class="d-flex">
						<b-nav-item
							v-for="(item, index) in leftItems"
							:key="index"
							:to="item.link"
						>
							{{ item.title }}
						</b-nav-item>
					</div>
					<li class="d-none d-lg-block mx-auto">
						<!-- <img class="navbar-logo" :src="logo" alt="Logo" /> -->

						<div class="logo-container">
							<router-link to="/">
								<img :src="logo" alt="Logo" class="img-fluid logo" />
							</router-link>
						</div>
					</li>

					<div class="d-flex">
						<b-nav-item
							v-for="(item, index) in rightItems"
							:key="index"
							:to="item.link"
						>
							{{ item.title }}
						</b-nav-item>
					</div>
				</b-navbar-nav>

				<!-- Nav items for mobile -->
				<b-navbar-nav class="d-flex d-lg-none flex-column">
					<b-nav-item
						v-for="(item, index) in leftItems.concat(rightItems)"
						:key="index"
						:to="item.link"
					>
						{{ item.title }}
					</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
		</b-container>
	</b-navbar>
</template>

<script>
export default {
	name: "NavHeader",
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		homeURL: {
			type: Object,
			default: () => ({}),
		},
		logo: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			// isCollapsed: false,
			hamburgerIcon: require("../../../assets/images/hamburger.svg"),
			crossIcon: require("../../../assets/images/cross-icon.svg"),
		};
	},
	computed: {
		leftItems() {
			return this.items.slice(0, this.items.length / 2);
		},
		rightItems() {
			return this.items.slice(this.items.length / 2);
		},
	},
	methods: {
		toggleCollapsed() {
			this.isCollapsed = !this.isCollapsed;
		},
		onResize() {
			if (window.innerWidth >= 992) {
				this.isCollapsed = false;
			}
		},
		onOpenMenuItem() {
			this.isMenuOpened = !this.isMenuOpened;
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},
};
</script>
<style>
/* Navbar Custom Styling */
.navbar-custom {
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 40%,
		rgba(0, 0, 0, 0) 100%
	);
	font-family: "Kanit", sans-serif;
	color: #fff !important;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
}

/* Navbar Collapse Margin Adjustment */
.navbar-collapse {
	margin-top: -20px;
}

/* Navbar Items Height and Alignment */
.navbar-custom .navbar-nav {
	align-items: stretch;
	height: 100%;
}

/* Navbar Container Height */
.navbar-custom .container {
	height: 100%;
}

/* Navbar Item Display and Alignment */
.navbar-custom .navbar-nav .nav-item {
	display: flex;
	align-items: center;
	height: 100%;
	font-size: 17px;
}

/* Navbar Link Styling and Alignment */
.navbar-custom .navbar-nav .nav-item .nav-link {
	display: flex;
	align-items: center;
	padding: 1.5rem 3px;
	height: 100%;
	width: 129px;
	position: relative; /* Required for :before to work */
	justify-content: center;
	text-align: left;
}

.navbar-custom .navbar-nav .nav-item .nav-link:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(180deg, #808080 0%, rgba(0, 0, 0, 0) 100%);

	z-index: -1;
	transition: opacity 0.2s ease-in-out;
	opacity: 0;
}

/* Navbar Item Hover Effect */
.navbar-custom .navbar-nav .nav-item:hover .nav-link:before {
	opacity: 1;
}

.navbar-custom .navbar-nav .nav-item:hover .nav-link {
	font-weight: bold;
}

/* Media Query for Devices with Max Width 1204px */
@media (max-width: 991px) {
	/* Navbar Nav Flex Start */
	.navbar-custom .navbar-nav {
		justify-content: flex-start;
		padding-left: 1em;
	}

	/* Navbar Brand Image Display None */
	.navbar-custom .navbar-brand img {
		display: none;
	}

	/* Navbar Collapse Margin Top */
	.navbar-collapse {
		margin-top: 25px !important;
	}

	/* Navbar Custom Styling for Smaller Screens */
	.navbar-custom .navbar-toggler-icon,
	.navbar-custom .navbar-collapse {
		background-color: transparent !important;
		box-shadow: none !important;
		backdrop-filter: blur(10px);
	}

	/* Navbar Custom Styling for Navbar Brand and Image */
	.navbar-custom .navbar-brand img {
		display: block;
	}
	.navbar ul {
		width: 300px !important;
		overflow-y: hidden !important;
	}
	.navbar ul li {
		margin-right: 0px !important;
	}

	.navbar-brand {
		width: 160px !important;
	}
}

/* Navbar Link Styling */
.navbar a {
	color: #fff !important;
	text-decoration: none;
	transition: color 2s ease-in-out;
}

/* Navbar Logo Header Hover */
.navbar .logo-header:hover {
	border: none;
}

/* Image Fluid Styling */
.img-fluid {
	width: 100%;
	height: auto;
}

/* Navbar Brand Width */
.navbar-brand {
	width: 210px;
}

.navbar-toggler-icon {
	background-image: none !important;
}

/* animation */

.navbar-toggler {
	position: relative;
	border: none;
	padding: 0;
	cursor: pointer;
	background: transparent;
	width: 30px;
	height: 24px;
	top: -10px;
}

.icon-bar {
	width: 30px;
	height: 3px;
	background-color: var(--iq-primary);

	position: absolute;
	transition: all 0.3s ease-in-out;
}

.top-bar {
	transform-origin: top left;
}

.middle-bar {
	opacity: 1;
}

.bottom-bar {
	transform-origin: bottom left;
}

.navbar-toggler.collapsed .top-bar {
	transform: translateY(8px) rotate(45deg);
}

.navbar-toggler.collapsed .middle-bar {
	opacity: 0;
}

.navbar-toggler.collapsed .bottom-bar {
	transform: translateY(-9px) rotate(-45deg);
}

.navbar-toggler {
	position: relative;
	border: none;
	padding: 0;
	cursor: pointer;
	background: transparent;
	width: 30px;
	height: 24px;
	outline: none;
}

.navbar-toggler img {
	width: 100%;
	height: auto;
}

.icon-fade-enter-active,
.icon-fade-leave-active {
	transition: opacity 0.3s;
}

.icon-fade-enter,
.icon-fade-leave-to {
	opacity: 0;
}

.logo-lg {
	/* position: absolute; */
	left: 50%;
	transform: translateX(-50%);
}
.logo-container {
	width: 196px;
	height: auto;
}
</style>
